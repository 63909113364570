
import { defineComponent, PropType, reactive, toRefs } from "vue";
import Color from "@/types/Color";

const __default__ = defineComponent({
  setup(props) {
    const colors = reactive(props.colorState);

    return { ...toRefs(colors) };
  },

  props: {
    colorState: {
      required: true,
      type: Object as PropType<Color>,
    },

    theme: {
      required: true,
      type: String,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "9e5a74d8": (_ctx.primaryBgColor),
  "ae3d774e": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__