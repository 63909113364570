
import { defineComponent, onMounted, ref } from "vue";
import WebViewer from "@pdftron/webviewer";

export default defineComponent({
  setup(props) {
    const viewer = ref<HTMLElement>();

    onMounted(() => {
      const path = `${process.env.BASE_URL}editor`;
      const mobileInput = document.getElementById("file_m_upload");
      const desktopInput = document.getElementById("file_d_upload");
      const themeHandler = document.getElementById("theme_handler");
      const themeStorage = localStorage.getItem("theme");

      if (viewer.value) {
        WebViewer({ path, initialDoc: "" }, viewer.value).then((instance) => {
          mobileInput?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];
            instance.UI.loadDocument(file, { filename: file.name });
          });

          desktopInput?.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];
            instance.UI.loadDocument(file, { filename: file.name });
          });

          if (themeStorage) {
            instance.UI.setTheme(themeStorage);
          }

          themeHandler?.addEventListener("click", () => {
            instance.UI.setTheme(props.theme);
          });
        });
      }
    });

    return { viewer };
  },

  props: {
    theme: {
      required: true,
      type: String,
    },
  },
});
