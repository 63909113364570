
import { defineComponent, onMounted, reactive, ref } from "vue";
import Header from "./components/Header.vue";
import MobileNav from "./components/MobileNav.vue";
import DesktopNav from "./components/DesktopNav.vue";
import Editor from "./components/Editor.vue";

export default defineComponent({
  setup() {
    const colorState = reactive({
      primaryColor: "#16181c",
      secondaryColor: "#343a40",
      thirdColor: "#192530",

      primaryBgColor: "#e7ebee",
      secondaryBgColor: "#f8f9fa",
      thirdBgColor: "#cfd8e0",
    });

    const defaultColorState = reactive({
      /* Light Config */
      primaryLight: "#16181c",
      secondaryLight: "#343a40",
      thirdLight: "#192530",

      primaryBgLight: "#e7ebee",
      secondaryBgLight: "#f8f9fa",
      thirdBgLight: "#cfd8e0",

      /* Dark Config */
      primaryDark: "#e7ebee",
      secondaryDark: "#f8f9fa",
      thirdDark: "#cfd8e0",

      primaryBgDark: "#16181c",
      secondaryBgDark: "#343a40",
      thirdBgDark: "#192530",
    });

    const theme = ref("light");

    const toDark = () => {
      colorState.primaryColor = defaultColorState.primaryDark;
      colorState.primaryBgColor = defaultColorState.primaryBgDark;
      colorState.secondaryColor = defaultColorState.secondaryDark;
      colorState.secondaryBgColor = defaultColorState.secondaryBgDark;
      colorState.thirdColor = defaultColorState.thirdDark;
      colorState.thirdBgColor = defaultColorState.thirdBgDark;
    };

    const toLight = () => {
      colorState.primaryColor = defaultColorState.primaryLight;
      colorState.primaryBgColor = defaultColorState.primaryBgLight;
      colorState.secondaryColor = defaultColorState.secondaryLight;
      colorState.secondaryBgColor = defaultColorState.secondaryBgLight;
      colorState.thirdColor = defaultColorState.thirdLight;
      colorState.thirdBgColor = defaultColorState.thirdBgLight;
    };

    const changeTheme = (data: string) => {
      if (data === "light") {
        theme.value = "dark";
        localStorage.setItem("theme", "dark");
        toDark();
      }
      if (data === "dark") {
        theme.value = "light";
        localStorage.setItem("theme", "light");
        toLight();
      }
    };

    onMounted(() => {
      const themeStorage = localStorage.getItem("theme");

      if (themeStorage) {
        theme.value = themeStorage;
      }

      if (themeStorage === "dark") {
        toDark();
      } else {
        toLight();
      }
    });

    return { colorState, theme, changeTheme };
  },

  components: { Header, MobileNav, DesktopNav, Editor },
});
