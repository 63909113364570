
import { defineComponent, PropType, reactive, ref, toRefs } from "vue";
import Color from "@/types/Color";

const __default__ = defineComponent({
  setup(props) {
    const colors = reactive(props.colorState);

    const fileName = ref("");

    const fileHandler = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      fileName.value = file.name;
    };

    return { ...toRefs(colors), fileName, fileHandler };
  },

  props: {
    colorState: {
      required: true,
      type: Object as PropType<Color>,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "77e7a3b8": (_ctx.secondaryBgColor),
  "2c6a022c": (_ctx.primaryBgColor),
  "2b71f6c6": (_ctx.primaryBgLight),
  "0912cf65": (_ctx.thirdBgColor),
  "afb849a2": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__