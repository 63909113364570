import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad34d5c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MobileNav = _resolveComponent("MobileNav")!
  const _component_DesktopNav = _resolveComponent("DesktopNav")!
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        colorState: _ctx.colorState,
        theme: _ctx.theme,
        onClickTheme: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTheme(_ctx.theme)))
      }, null, 8, ["colorState", "theme"]),
      _createVNode(_component_MobileNav, { colorState: _ctx.colorState }, null, 8, ["colorState"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DesktopNav, { colorState: _ctx.colorState }, null, 8, ["colorState"]),
      _createVNode(_component_Editor, { theme: _ctx.theme }, null, 8, ["theme"])
    ])
  ]))
}